import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ProcessParser } from 'advoprocess';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import {
  ParsedFetchString,
  parseFetchString,
} from 'advoprocess/lib/types/question';
import _ from 'lodash';
import {
  Observable,
  Subject,
  firstValueFrom,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { filterTerm } from 'src/app/common/helpers';
import { fetchProcessVariable } from './fetch-var-functions';
import { LegalProcessService } from 'src/api';
import { ProcessService } from '../../process.service';
import { ActivatedRoute } from '@angular/router';

export interface OptionType {
  text: string;
  value?: any;
  target: string;
  selected?: boolean;
}

@Component({
  selector: 'app-options-input',
  templateUrl: './options-input.component.html',
})
export class OptionsInputComponent implements OnInit {
  @Input() message: any;
  @Input() value: any;
  @Input() required: boolean = true;
  @Output() hasError = new EventEmitter<boolean>();
  @Input() inline: boolean;
  @Input() service: ProcessService;

  error: boolean = false;

  @Input() parser: ProcessParser;

  @HostBinding('attr.data-appearance')
  appearance: OptionsInputAppearance = 'buttons';

  @Output() buttonClick = new EventEmitter<any>();
  @Output() changed = new EventEmitter<any>();

  formControl = new UntypedFormControl();

  constructor(
    private translator: TranslateService,
    private processService: LegalProcessService,
    private activatedRoute: ActivatedRoute
  ) { }

  allOptions: OptionType[] | undefined = undefined;
  fetchFn: (searchString: string) => Observable<OptionType> | undefined;

  displayFn(value: OptionType | undefined): string | undefined {
    return value?.text;
  }

  private async updateAllOptions(): Promise<void> {
    const options = this.message.responseRequest?.params?.options;
    if (!options) return;
    if (typeof options === 'string' && options.startsWith('fetch::')) {
      this.allOptions = [];
      const parsed = parseFetchString(options);
      if (!parsed) return;
      await this.fetchAllOptionsNow(options, parsed);
    } else {
      this.allOptions = options;
    }
  }

  private async fetchAllOptionsNow(options: string, parsed: ParsedFetchString): Promise<void> {
    if (!parsed) return;
    let name = parsed.name;
    let data;
    switch (parsed.source) {
      case 'dossier':
        const entry = this.parser.dataStore.get(name);
        if (Array.isArray(entry)) {
          data = entry;
        } else {
          const full = this.parser.dataStore.get(name, true);
          data = full;
        }
        break;
      case 'process':
        const processId =
          this.service?.processDirectSource?.raw?.id ??
          this.service?.executionState?.initial_processid;
        if (processId) {
          const realm = this.activatedRoute.snapshot.paramMap.get('realm');
          data = await firstValueFrom(
            fetchProcessVariable(processId, this.processService, name, realm)
          );
        }
        break;
    }
    if (!data?.length || !_.isArray(data)) return;
    this.allOptions = data.map((d) => ({
      text: this.getTextForOption(d, parsed),
      target: '_blank',
      value: d,
    }));
  }

  private getTextForOption(d: any, parsed: ParsedFetchString) {
    if (!d) return '---';
    if (typeof d === 'string') {
      return d;
    }
    if (_.isObject(d)) {
      let labelKeyValue;
      if (parsed.labelKey?.length) {
        labelKeyValue = d[parsed.labelKey];
      }
      return labelKeyValue ?? d['name'] ?? d['label'] ?? JSON.stringify(d);
    }
  }

  ngOnInit(): void {
    this.appearance = this.getOptionsAppearance();
    this.updateAllOptions().then(() => {
      let prevValue;
      if (
        this.appearance === 'inline-checkbox' ||
        this.appearance === 'checkbox'
      ) {
        prevValue =
          this.allOptions?.filter((o) => this.value?.includes(o.text)) ?? [];
        prevValue.forEach((p) => (p.selected = true));
      } else {
        prevValue = this.allOptions?.find((o) => o.text === this.value);
      }
      if (prevValue) {
        this.formControl.setValue(prevValue);
        this.formControl.markAsTouched();
        this.formControl.markAsDirty();
      }
    });
    if (this.required) {
      this.formControl.addValidators([Validators.required]);
    }
    this.formControl.valueChanges.subscribe((value) => {
      if (this.inline) {
        this.buttonClick.emit(this.formControl.value);
      }
      this.updateHasError();
    });
    this.updateHasError();
  }

  private updateHasError() {
    const isMultiple =
      this.appearance === 'inline-checkbox' || this.appearance === 'checkbox';
    if (isMultiple) {
      this.error = this.required && !this.formControl.value?.length;
    } else {
      this.error = this.required && !this.formControl.value;
    }
    this.hasError.emit(this.error);
  }

  logInCheckboxes() {
    this.buttonClick.emit(this.allOptions.filter((o) => o?.selected));
    this.error = this.required && !this.allOptions?.some((c) => c.selected);
    this.hasError.emit(this.error);
  }

  get checkboxGoDisabled(): boolean {
    return this.message.responseRequest?.params?.mustSelect
      ? this.allOptions.some((o) => !o?.selected)
      : false;
  }

  public getOptionsAppearance(): OptionsInputAppearance {
    if (this.message.responseRequest?.type === 'check') {
      if (this.message.responseRequest?.params?.format === 'inline') {
        return 'inline-checkbox';
      } else {
        return 'checkbox';
      }
    } else if (this.inline) {
      if (this.message.responseRequest?.params?.format === 'horizontal') {
        return 'horizontal';
      } else {
        return 'dropdown';
      }
    } else {
      if (this.message.responseRequest?.params?.format === 'dropdown') {
        return 'dropdown';
      }
      return 'buttons';
    }
  }

  abortQuery$ = new Subject<void>();
  lastOptionResult: MenuEntry<OptionType>[] = [];

  queryOptions(searchTerm: string): Observable<MenuEntry<OptionType>[]> {
    const isMultiple = this.appearance === 'inline-checkbox';
    this.abortQuery$.next();
    return timer(0).pipe(
      switchMap(() => of(this.allOptions)),
      map((opts) =>
        opts.map(
          (p): MenuEntry<OptionType> => ({
            name: p.text,
            value: p,
          })
        )
      ),
      tap((x) => (this.lastOptionResult = x)),
      startWith(this.lastOptionResult),
      takeUntil(this.abortQuery$),
      tap((l) =>
        l.forEach((e) => {
          e.icon = isMultiple
            ? this.formControl.value?.includes(e.value)
              ? 'check_box'
              : 'check_box_outline_blank'
            : null;
        })
      ),
      map((l) =>
        l
          .filter((e) => filterTerm(searchTerm, e, this.translator))
          ?.slice(0, 250)
      )
    );
  }

  selectOption(entry: MenuEntry<OptionType>, multiple: boolean) {
    if (multiple) {
      const existingIndex = this.formControl.value?.indexOf(entry.value);
      if (existingIndex !== -1) {
        this.formControl.value.splice(existingIndex, 1);
        this.formControl.setValue(this.formControl.value);
        entry.value.selected = false;
      } else {
        this.formControl.setValue([...this.formControl.value, entry.value]);
        entry.value.selected = true;
      }
    } else {
      this.formControl.setValue(entry.value);
    }
  }

  get selectionLabel(): string {
    const value = this.formControl.value;
    if (!value) return '';
    if (Array.isArray(value)) {
      return value
        .map((v) => v?.text)
        .filter((n) => !!n)
        .join(', ');
    } else {
      return value.text;
    }
  }
}

export type OptionsInputAppearance =
  | 'buttons'
  | 'dropdown'
  | 'checkbox'
  | 'horizontal'
  | 'inline-checkbox';
